import React from "react"

import { Error, CheckboxContainer, Checkbox } from "../../global/common/inputs"
import { highlightFieldIfError } from "./helpers"

const Field = ({ name, label, touched, allErrors }) => {
  const { acceptance: error } = allErrors

  return (
    <CheckboxContainer>
      <Checkbox
        type="checkbox"
        name={name}
        id={name}
        style={highlightFieldIfError(allErrors, name, touched)}
      />
      <span>
        {label}
        {touched && error ? (
          <>
            <br />
            <Error>{error}</Error>
          </>
        ) : null}
      </span>
    </CheckboxContainer>
  )
}

export { Field as Checkbox }
