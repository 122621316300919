import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { BannerContainer, BannerWrapper } from "./common/containers"
import { MainTitle } from "./common/text"

import { Button } from "./atoms/Button"
import Nav from "./nav/Nav"

const IntroWrapper = styled(BannerWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 0 var(--section-spacing);

  @media ${maxD.mobileL} {
    padding: 0 0 80px;
  }
`

const IntroTitle = styled(MainTitle)`
  max-width: 1080px;

  text-align: center;
  padding-bottom: 40px;
`

const IntroButton = styled(Button)`
  margin-top: 30px;
`

const SimpleHeader = ({ pageContext, data }) => {
  const header = data.contactIntro

  const button = {
    title: data.contactIntroLink?.title,
    url: data.contactIntroLink?.url,
    target: data.contactIntroLink?.target,
  }

  return (
    <BannerContainer>
      <Nav pageContext={pageContext} />
      <IntroWrapper>
        <IntroTitle dangerouslySetInnerHTML={{ __html: header }}></IntroTitle>
        {button && button.url && (
          <IntroButton theme="green" to={button.url} target={button.target}>
            {button.title}
          </IntroButton>
        )}
      </IntroWrapper>
    </BannerContainer>
  )
}

export { SimpleHeader }
