import React from "react"

import {
  FancyError,
  HiddenLabel,
  FormLine,
  TextArea,
} from "../../global/common/inputs"

const Field = ({ name, label, touched, error }) => (
  <FormLine>
    <HiddenLabel htmlFor={name}>{label}</HiddenLabel>
    {touched && error ? (
      <FancyError style={{ "--color": "#fff" }}>{error}</FancyError>
    ) : null}
    <TextArea component="textarea" name={name} id={name} placeholder={label} />
  </FormLine>
)

export { Field as TextArea }
