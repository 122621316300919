import { getIn } from "formik"

const handleValidation = (values, language) => {
  let errors = {}

  if (!values.name) {
    errors.name = language === "en" ? "This field is required" : "To pole jest wymagane"
  }

  if (!values.email) {
    errors.email = language === "en" ? "This field is required" : "To pole jest wymagane"
  } else if (! /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(values.email)) {
    errors.email = language === "en" ? "There is something wrong with given e-mail address" : "Podany e-mail ma niepoprawny format"
  }

  if (!values.message) {
    errors.message = language === "en" ? "This field is required" : "To pole jest wymagane"
  }

  if (!values.acceptance) {
    errors.acceptance = language === "en" ? "This field is required" : "To pole jest wymagane"
  }

  return errors
}

const highlightFieldIfError = (error, fieldName, touched) => {
  if (getIn(error, fieldName) && touched)
    return {
      "--input-theme": "var(--red)",
      "--input-transparent-theme": "var(--red-transparent-min)",
    }
}

export { handleValidation, highlightFieldIfError }
