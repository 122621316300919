import React from "react"

import {
  FancyError,
  HiddenLabel,
  FormLine,
  TextField,
} from "../../global/common/inputs"

const Field = ({ name, label, touched, error, type = "text" }) => (
  <FormLine>
    <HiddenLabel htmlFor={name}>{label}</HiddenLabel>
    {touched && error ? (
      <FancyError style={{ "--color": "#fff" }}>{error}</FancyError>
    ) : null}
    <TextField type={type} name={name} id={name} placeholder={label} />
  </FormLine>
)

export { Field as TextField }
