import React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { maxD } from "../../assets/styles/helpers/devices"

import { greenBox, Overlay } from "../global/common/ornaments"
import { UnderlinedLink } from "../global/common/links"
import { UnstyledList } from "../../components/global/common/essentials"

const elementsPadding = css`
  padding: 1.2rem 0;
`

const People = styled.section`
  position: relative;

  ${greenBox}

  &::after {
    top: 20px;
    left: -20px;
  }
`

const PeopleInner = styled(UnstyledList)`
  display: flex;
  flex-wrap: wrap;
`

const Person = styled.li`
  width: 50%;
  margin-bottom: 35px;

  @media ${maxD.tablet} {
    width: 100%;
  }

  @media ${maxD.phoneL} {
    margin-bottom: 15px;
  }
`

const ImageContainer = styled.figure`
  position: relative;

  width: 100%;
  height: 500px;
  background-size: cover;

  @media ${maxD.mobileM} {
    height: 120vw;
  }
`

const PersonsData = styled(Overlay)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  z-index: 2;

  ${elementsPadding}

  color: #fff;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 40%,
    rgba(0, 0, 0, 0.4) 100%
  );
`

const ContactData = styled.footer`
  ${elementsPadding}
`

const ContactWay = styled(UnderlinedLink)`
  margin-bottom: 1rem;
  font-size: 15px;
`

function Executive({ data }) {
  const people = data.contactPeople

  return (
    <People>
      <PeopleInner>
        {people &&
          people.map(person => {
            const {
              personName: name,
              personPosition: position,
              phone,
              email,
            } = person

            const image = {
              url: person.personImage?.localFile.childImageSharp
                .gatsbyImageData,
              alt: person.personImage?.altText,
            }

            return (
              <Person>
                <ImageContainer>
                  <GatsbyImage
                    image={image.url}
                    alt={image.alt}
                    imgStyle={{ backgroundPosition: "top" }}
                    className="full"
                  />
                  <PersonsData as="div">
                    {name && <h2>{name}</h2>}
                    {position && <p>{position}</p>}
                  </PersonsData>
                </ImageContainer>
                <ContactData>
                  <ContactWay as="a" href={`tel:${phone.replace(" ", "")}`}>
                    {phone}
                  </ContactWay>
                  <br />
                  <ContactWay as="a" href={`mailto:${email}`}>
                    {email}
                  </ContactWay>
                </ContactData>
              </Person>
            )
          })}
      </PeopleInner>
    </People>
  )
}

export { Executive }
