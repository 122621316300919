import React from "react"
import styled, { css } from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { SecondaryTitle } from "../global/common/text"
import { SocialMedia } from "./../global/SocialMedia"
import { UnderlinedLink } from "../global/common/links"

import Arrow from "../../assets/images/svg/right-arrow.svg"
import { isLinkAnAnchor } from "../../assets/styles/helpers/common"

const elementsPadding = css`
  padding: 2.6rem 2rem;
`

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  border: 1px solid var(--faded-gray);
`

const Column = styled.div`
  position: relative;
  width: 50%;
  ${elementsPadding}

  &:first-child {
    @media ${minD.tablet} {
      border-right: 1px solid var(--faded-gray);
    }

    @media ${maxD.tablet} {
      padding-bottom: 3px;
    }
  }

  @media ${maxD.tablet} {
    width: 100%;
  }
`

const ColumnTitle = styled(SecondaryTitle)`
  padding-bottom: 30px;
`

const Row = styled.div`
  display: flex;

  &:not(:last-child) {
    padding-bottom: 40px;
  }
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 40px;
  height: 40px;

  margin-right: 1.5rem;
  border-radius: 50%;
  background-color: var(--faded-green);

  svg {
    width: 16px;
    height: 20px;
    fill: var(--main-green);
  }
`

const RowContent = styled.div``

const RowTitle = styled.h3`
  font-size: 1rem;
  padding-bottom: 0.2rem;
`

const RowDescription = styled.p`
  font-weight: 300;
`

const RowLink = styled(UnderlinedLink)`
  padding-top: 0.8rem;
`

const Address = styled.address`
  font-weight: 300;
  line-height: 1.8;

  span {
    font-weight: 500;
  }
`

const SocialMediaContainer = styled.div`
  @media ${minD.tablet} {
    ${elementsPadding}
    position: absolute;

    width: 100%;

    left: 0;
    bottom: 0;
    right: 0;
  }

  @media ${maxD.tablet} {
    margin: 35px 0 -10px;
  }
`

function Data({ data }) {
  const officeHeader = data.contactOfficeHeader
  const officeData = data.contactOfficeData

  const detailsHeader = data.contactDetailsHeader
  const details = data.contactDetails

  return (
    <Container>
      <Column>
        <ColumnTitle>{officeHeader}</ColumnTitle>
        {officeData &&
          officeData.map(row => {
            const {
              contactOfficeIcon: icon,
              contactOfficeSubHeader: header,
              contactOfficeDescription: description,
              contactOfficeLink: button,
            } = row

            return (
              <Row>
                <Icon dangerouslySetInnerHTML={{ __html: icon }} />
                <RowContent>
                  <RowTitle>{header}</RowTitle>
                  <RowDescription>{description}</RowDescription>
                  {button && button.url && (
                    <RowLink
                      to={button.url}
                      target={button.target}
                      anchor={isLinkAnAnchor(button.url)}
                    >
                      {button.title}
                      <Arrow />
                    </RowLink>
                  )}
                </RowContent>
              </Row>
            )
          })}
      </Column>
      <Column>
        <ColumnTitle
          dangerouslySetInnerHTML={{ __html: detailsHeader }}
        ></ColumnTitle>
        <Address dangerouslySetInnerHTML={{ __html: details }}></Address>
        <SocialMediaContainer>
          <SocialMedia />
        </SocialMediaContainer>
      </Column>
    </Container>
  )
}

export { Data }
