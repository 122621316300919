import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { WideWrapper } from "../global/common/containers"
import { SecondaryTitle } from "../global/common/text"
import { Button } from "../global/atoms/Button"

const Container = styled(WideWrapper)`
  min-width: 100%;
  padding-bottom: calc(var(--section-spacing) * 0.65);

  @media ${maxD.laptop} {
    padding-top: calc(var(--section-spacing) * 0.6);
  }

  @media ${maxD.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Title = styled(SecondaryTitle)`
  @media ${minD.tablet} {
    padding-right: 2.5rem;
  }

  @media ${maxD.tablet} {
    padding-bottom: 1.5rem;
  }
`

const BriefInvite = ({ data }) => {
  const button = {
    title: data.contactBriefInviteLink?.title,
    url: data.contactBriefInviteLink?.url,
    target: data.contactBriefInviteLink?.target,
  }

  const title = data.contactBriefInvite

  return (
    <Container flex alignItems="c">
      <Title
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ "--spacing-bottom": "0" }}
      />
      {button && button.url && (
        <Button to={button.url} target={button.target} theme="green">
          {button.title}
        </Button>
      )}
    </Container>
  )
}

export default BriefInvite
